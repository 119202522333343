import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: I should be concerned if my teenager has stopped doing things that they used to enjoy." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Depression can lead to a teenager cutting down on things that they used to
        enjoy or no longer getting pleasure out of these activities. See{' '}
        <GoTo to="/m10-seeking-help/04-depression-signs" mdxType="GoTo">Depression: What should I look for</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: I should avoid talking with my teenager about any mental health difficulties they may be having, to give them privacy." fallbackResponse={<p>
        The correct answer is: <b>False</b>. You should encourage your teenager to talk about what is on their mind.
        Really listen to your teenager’s concerns because you might be able to help them seek help. See{' '}
        <GoTo to="/m10-seeking-help/07-what-should-I-do" mdxType="GoTo">Things aren’t okay – what should I do</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: I should try to understand whether the change in my teenager’s mood or behaviour is due to a temporary situation or is an ongoing problem." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Try to understand if your teenager is experiencing a change in their mood or
        behaviour due to a temporary situation, such as upcoming exams, or if the situation is more of an ongoing
        problem. If it seems to be more of an ongoing problem, then you should encourage your teenager to seek
        professional help. See{' '}
        <GoTo to="/m10-seeking-help/07-what-should-I-do" mdxType="GoTo">Things aren’t okay – what should I do</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: It is best to wait until my teenager’s symptoms are severe before seeking professional help." fallbackResponse={<p>
        The correct answer is: <b>False</b>. When you can see that the symptoms are affecting your teenager’s
        functioning and have lasted for more than a few weeks, it’s important to get help early. This can help to reduce
        the severity of depression and clinical anxiety and prevent future occurrences. See{' '}
        <GoTo to="/m10-seeking-help/07-what-should-I-do" mdxType="GoTo">Things aren’t okay – what should I do</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: If my teenager has previously had depression, I should encourage them to reward themselves for reaching small goals." fallbackResponse={<p>
        The correct answer is: <b>True</b>. If your teenager has previously had depression, a good self-help strategy is
        to reward themselves for reaching small goals. This is to encourage them to become more engaged with life, give
        them more positive experiences and build a sense of achievement. See{' '}
        <GoTo to="/m10-seeking-help/10-previous-depression" mdxType="GoTo">What if my teenager has previously had depression</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/Oriental_quiz.svg" alt="thinking man" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      